/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import useSiteMetadata from '../../hooks/useSiteMetadata';

const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

const siteUrl = process.env.SITE_URL;

const JSONLDStructure = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Central do Frete',
  logo:
    'https://blog.centraldofrete.com/wp-content/uploads/2019/01/logo-10.08.54.png',
  url: 'https://centraldofrete.com/',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Alameda Terracota, 215 - conj 1120 - Cerâmica',
    addressLocality: 'São Caetano do Sul',
    postalCode: '09531-190',
    addressCountry: 'Brazil',
  },
  sameAs: [
    'https://www.facebook.com/centraldofretecom/',
    'https://twitter.com/centralfretecom',
    'https://www.instagram.com/centraldofretecom/',
    'https://www.youtube.com/channel/UCia5BwWsIJDFl-gGlKwSWZA',
    'https://www.linkedin.com/company/centraldofrete-com/',
    'https://blog.centraldofrete.com/',
  ],
};

const generateMeta = ({ keywords, meta, metaDescription, title }) =>
  [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: `${siteUrl}centraldofrete.jpg`,
    },
    {
      property: 'og:image:type',
      content: 'image/jpeg',
    },
    {
      property: 'og:image:width',
      content: '600',
    },
    {
      property: 'og:image:height',
      content: '315',
    },
    {
      property: 'og:image:alt',
      content: 'Central do Frete - Facilitando Conexões -> CentraldoFrete.com',
    },
  ]
    .concat(
      activeEnv === 'production'
        ? []
        : {
            name: 'robots',
            content: 'noindex, nofollow',
          }
    )
    .concat(
      keywords.length > 0
        ? {
            name: 'keywords',
            content: keywords.join(', '),
          }
        : []
    )
    .concat(meta);

function SEO({ description, lang, meta, keywords, title }) {
  const { description: metaDescription = description } = useSiteMetadata();

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate="%s"
      meta={generateMeta({
        keywords,
        meta,
        metaDescription,
        title,
      })}
    >
      <script type="application/ld+json">
        {JSON.stringify(JSONLDStructure)}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  description: '',
  keywords: [],
  lang: 'en',
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
